import { faUserCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Container, createStyles, Grid, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SaunaIcon } from '../src/assets/sauna-svgrepo-com.svg';
import { IRuuviTag, IRuuviTags, IWeatherDweet } from './models/weatherDweet';
import { WeatherPanel } from './panels/WeatherPanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '40px'
        },
        grid: {},
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary
        },
        button: {
            position: 'absolute',
            top: '10px',
            right: '10px'
        }
    })
);

export interface ITag {
    [id: string]: {
        name: string;
        type: string;
    }
}

export interface IDashboardConfig {
    dweetUrl: string;
    tags: ITag;
}

interface IProps {
    firebase: any
}

export const Dashboard = (props: IProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [config, setConfig] = useState<IDashboardConfig>();
    const [updated, setUpdated] = useState(Date.now());
    const [tagValues, setTagValues] = useState<IRuuviTags>();

    /* Get config from db */
    useEffect(() => {
        const ref = firebase.database().ref('config/');
        ref.once('value', (snapshot) => {
            const value: IDashboardConfig = snapshot.val();
            setConfig(value);
        }).catch(console.error);
    }, []);

    /* Read tag values from dweet.io */
    useEffect(() => {
        if (!config?.dweetUrl) {
            return;
        }
        const abortController = new AbortController();
        fetch(config.dweetUrl, {signal: abortController.signal})
            .then(async (response) => {
                const data: IWeatherDweet = await response.json();
                if (data.this === 'failed') {
                    console.error('Weather data not found', data);
                } else {
                    setTagValues(data.with[0].content)
                }

            })
            .catch((e) => {
                console.error('Failed to load weather data', e);
            });

        return () => {
            abortController.abort();
        };
    }, [updated, config?.dweetUrl]);

    /* Update tag values once per minute */
    useEffect(() => {
        const interval = setInterval(() => {
            setUpdated(Date.now());
        }, 60000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    const handleLogout = () => {
        props.firebase.app().auth().signOut();
        handleMenuClose();
    };
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    const ruuviPanels = config?.tags ? Object.entries(config.tags).map((entry) => {
        const [tagName, tagConfig] = entry;
        const tagData: IRuuviTag | undefined = tagValues ? tagValues[tagName] : undefined;
        return (
            <Grid item key={tagName}>
                {tagConfig.type === 'weather' ? (
                    <WeatherPanel values={tagData} title={tagConfig.name}/>
                ) : tagConfig.type === 'sauna' ? (
                    <WeatherPanel values={tagData} title={tagConfig.name} icon={<SaunaIcon color="#f00"/>}/>
                ) : null}
            </Grid>
        );
    }) : null;

    return (
        <>
            <Container className={classes.root}>
                <Button onClick={handleClick} className={classes.button}>
                    <FontAwesomeIcon icon={faUserCog} color={'#88b39b'} size={'lg'}/>
                </Button>

                <Grid container className={classes.grid} spacing={2}>
                    {ruuviPanels}
                </Grid>
            </Container>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleLogout}>Kirjaudu ulos</MenuItem>
            </Menu>
        </>
    );
};
