import firebase from 'firebase/app';
import { PropsWithChildren } from 'react';

interface IProps {
    user?: firebase.User
}

export const IfNotAuthenticated = (props: PropsWithChildren<IProps>) => {
    if (props.user) {
        return null;
    }
    return (<>{props.children}</>);
}