import { Button, CircularProgress } from '@material-ui/core';
import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import './Login.css';

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const handleLogin = () => {
    firebase.auth().signInWithPopup(googleAuthProvider).catch((e) =>
        console.error('Failed to login with Google', e)
    );
}

export const Login = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <>
            {loading ? (
                <CircularProgress/>
            ) : (
                <Button className="google-button" onClick={handleLogin}/>
            )}
        </>
    );
};
