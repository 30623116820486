import { faClock, faCloud, faCloudRain, faMapMarkerAlt, faQuestion, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDistance } from 'date-fns'
import { fi } from 'date-fns/locale'
import React from 'react';
import { IRuuviTag } from '../models/weatherDweet';
import { Panel } from './Panel';

interface IProps {
    title: string;
    values?: IRuuviTag;
    icon?: any;
}

export const WeatherPanel = (props: IProps) => {
    const values = props.values;
    const weatherIcon = !values?.humidity
        ? faQuestion
        : values?.humidity > 80
            ? faCloudRain
            : values?.humidity > 50
                ? faCloud
                : faSun;

    return (
        <>
            {values?.temperature ? (
                <Panel>
                    <div className="location">
                        <FontAwesomeIcon icon={faMapMarkerAlt} color={'#5f7d6d'}/>
                        <span>{props.title}</span>
                    </div>
                    <div>
                        {
                            props.icon ?
                                (<div style={{width: '57px', margin: '0 auto'}}>{props.icon}</div>) :
                                (
                                    <FontAwesomeIcon
                                        color="#5f7d6d"
                                        icon={weatherIcon}
                                        size={'3x'}/>
                                )
                        }
                    </div>
                    <div className="value">{values?.temperature && Math.round(values?.temperature)}°</div>
                    <div className="updated">
                        <FontAwesomeIcon icon={faClock} color={'#8d8fb3'}/>
                        {formatDistance(values?.updated, new Date(), {locale: fi})} sitten.
                    </div>
                </Panel>
            ) : null}
        </>
    );

}