import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";
import React, { useEffect, useState } from 'react';
import './App.css';
import { firebaseConfig } from './config/firebase-config';
import { Dashboard } from './Dashboard';
import { IfAuthenticated } from './firebase/IfAuthenticated';
import { IfNotAuthenticated } from './firebase/IfNotAuthenticated';
import { Login } from './Login';

function App() {
    const [user, setUser] = useState<firebase.User | undefined>();
    useEffect(() => {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        firebase.auth().onAuthStateChanged(firebaseUser => {
            setUser(firebaseUser || undefined);
        });
    }, []);

    return (
        <div className="App">
            <IfAuthenticated user={user}>
                <Dashboard firebase={firebase}/>
            </IfAuthenticated>
            <IfNotAuthenticated user={user}>
                <Login/>
            </IfNotAuthenticated>
        </div>
    );
}

export default App;
