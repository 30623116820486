import { Button } from '@material-ui/core';
import firebase from 'firebase/app';
import { PropsWithChildren } from 'react';

interface IProps {
    user?: firebase.User
}

export const IfAuthenticated = (props: PropsWithChildren<IProps>) => {

    const handleLogout = () => {
        firebase.app().auth().signOut();
    }

    if (!props.user) {
        return null;
    }
    const emailDomain = props.user.email?.split('@')[1];
    const allowed = process.env.REACT_APP_VALID_AUTH_DOMAIN;
    if (emailDomain !== allowed) {
        return (
            <div>
                <div>Only users with {allowed} -email are allowed to sign in.</div>
                <div><Button onClick={handleLogout}>Sign out</Button></div>
            </div>
        );
    }
    return (<>{props.children}</>);
}